.edit-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .edit-dialog {
    background: white;
    color: #232323;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .edit-dialog input {
    margin-bottom: 10px;
  }
  
  .edit-dialog h2 {
    margin-top: 0;
  }
  
  .edit-dialog ul {
    list-style-type: none;
    padding: 0;
  }
  
  .edit-dialog li {
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .edit-dialog button {
    padding: 10px;
    border: none;
    background-color: #a3d687;
    color: #060606;
    border-radius: 5px;
    cursor: pointer;
  }

  .edit-dialog button:hover {
    background: #8eec5b;
  }

  .edit-dialog button:hover {
    background: #8eec5b;
  }
  
  .edit-dialog .cancel-button {
    background-color: #6c757d;
  }

  .edit-dialog .cancel-button:hover {
    background-color: #5a6268;
  }
  