:root {
  --color-welcome-text: #b0b0b0;
  --color-menu-btn-text: #767676;
  /*Date boxes*/
  --color-habit-completed: #a3d687;
  --color-longest-streak: #e8a3ff;
  --color-missing-date: #3f3f3f;
  /*Misc*/
  --color-menu-btn-hover-text: #e3e3e3;
  --color-menu-btn: #2d2d2d;
  --color-error-text: #ff4d4d;
  --color-error-bg: #ffe6e6;
  --color-divider-dark: rgba(79, 79, 79, 0.3);
  --color-divider-light: rgba(0, 0, 0, 0.1);
  /*Habit check box*/
  --color-checkbox-unchecked-border: #a3d687;
  --color-checkbox-hover-border: #8eec5b;
  --color-checkbox-stroke: #060606;
  --color-checkbox-onclick: #2c6a0a;
  --color-checkbox-checked-border: #62fb0f;
  --color-checkbox-checked-bg: #62fb0f;
  --color-checkbox-text: #ffffff;
  --color-google-btn-bg: #a3d687;
  --color-google-btn-hover-bg: #8eec5b;
  --color-google-btn-text: #060606;
}

.header {
  display: block;
  margin: auto;
  margin-top: 30px;
}

.header-logo {
  margin: auto;
}

.header-logo img {
  width: 200px;
}

.header-menu {
  max-width: 600px;
  margin: auto;
  display: inline-flex;
}

.welcome-text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 15px;
  font-size: 18px;
  color: var(--color-welcome-text);
}

.menu-btn {
  display: inline-flex;
  margin: 0px;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
  border: 0px;
  border-radius: 4px;
  color: var(--color-menu-btn-text);
  background-color: var(--color-menu-btn);
  transition: background-color 0.3s, color 0.3s;
}

.habit-tracker {
  padding: 20px;
  padding-top: 0px;
  max-width: 600px;
  margin: auto;
}

p {
  text-align: left;
}

h2 {
  font-weight: 400;
}

.login-page p {
  text-align: center;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

button {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-message {
  color: var(--color-error-text);
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-text);
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.date-box {
  display: inline-block;
  height: 18px;
  width: 18px;
  margin: 0px 2px 0px 2px;
  border: 0px;
  background-color: var(--color-habit-completed);
  border-radius: 4px;
  font-size: 14px;
  position: relative;
}

.date-box::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background: #333;
  color: #fff;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.date-box:hover::after {
  opacity: 1;
  visibility: visible;
}

.missing-date {
  background-color: var(--color-missing-date);
  border: 0px;
}

.longest-streak {
  background-color: var(--color-longest-streak);
}

.new-month {}

.toggle-dates-button {
  border: 0px;
  display: inline-block;
  position: relative;
  height: 18px;
  width: 18px;
  margin: 2px;
}

.toggle-dates-button svg {
  color: #5a5a5a;
  height: 12px;
  width: 12px;
  position: relative;
  bottom: 3px;
  left: 3px;
}

.habit-text.completed {
  text-decoration: line-through;
}

.habit-item {
  align-items: left;
  gap: 10px;
  margin-bottom: 20px;
}

.btn-delete {
  display: block;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 0px;
  border-radius: 4px;
  color: var(--color-menu-btn-hover-text);
  background-color: var(--color-missing-date);
  transition: background-color 0.1s, color 0.1s;
}

.completion-dates {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.habit-menu-bar {
  margin-top: 0px;
  font-size: 0.9em;
  color: #999999;
}

.habit-menu-bar p {
  margin: 0;
}

.delete-btn {
  display: inline;
  position: relative;
  top: 2px;
}

.delete-btn svg {
  height: 16px;
  position: relative;
  color: rgb(58, 58, 58);
}

.add-section {
  width: 100%;
}

.add-btn {
  float: left;
}

.add-btn-label {
  float: left;
  padding-left: 10px;
  padding-top: 5px;
}

.add-btn svg {
  color: var(--color-habit-completed);
}

.divider {
  border: 0;
  height: 0;
  width: 80%;
  border-top: 1px solid var(--color-divider-light);
  border-bottom: 1px solid var(--color-divider-dark);
  margin-top: 0px;
  margin-bottom: 0px;
}

.bi-lightning-charge-fill {
  fill: var(--color-habit-completed);
  width: 0.9em;
  height: 0.9em;
}

.bi-lightning-charge-fill-longest {
  fill: var(--color-longest-streak);
  width: 0.9em;
  height: 0.9em;
}

.login-with-google-btn {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 0px;
  border-radius: 4px;
  background-color: var(--color-google-btn-bg);
  color: var(--color-google-btn-text);
  transition: background-color 0.3s, color 0.3s;
}

.login-with-google-btn:hover {
  background-color: var(--color-google-btn-hover-bg);
  transition: background-color 0.3s, color 0.3s;
}

.today-habit-checkbox {
  display: flex;
}

.habit-checkbox {
  display: none;
}

.today-habit-checkbox .cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.today-habit-checkbox .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.today-habit-checkbox .cbx span:first-child {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid var(--color-checkbox-unchecked-border);
  transition: all 0.2s ease;
}

.today-habit-checkbox .cbx span:first-child svg {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 6px;
  fill: none;
  stroke: var(--color-checkbox-stroke);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.today-habit-checkbox .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--color-checkbox-onclick);
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition-delay: 0.2s;
}

.today-habit-checkbox .cbx span:last-child {
  margin-left: 8px;
}

.today-habit-checkbox .cbx:hover span:first-child {
  border-color: var(--color-checkbox-hover-border);
}

.today-habit-checkbox .habit-checkbox:checked + .cbx span:first-child {
  border-color: var(--color-checkbox-checked-border);
  background: var(--color-checkbox-checked-bg);
  animation: check-15 0.6s ease;
}

.today-habit-checkbox .habit-checkbox:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.today-habit-checkbox .habit-checkbox:checked + .cbx span:first-child:before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}

.today-habit-checkbox .habit-checkbox:checked + .cbx span:last-child {
  color: var(--color-checkbox-text);
  transition: all 0.3s ease;
}

.today-habit-checkbox .habit-checkbox:checked + .cbx span:last-child:after {
  transform: scaleX(1);
  transition: all 0.3s ease;
}

@keyframes check-15 {
  50% {
    transform: scale(1.2);
  }
}