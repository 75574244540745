.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    background: white;
    color: #232323;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .popup-content input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    max-width: 300px;
  }
  
  .popup-content button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    background-color: #a3d687;
    color: #060606;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background: #8eec5b;
  }
  
  .popup-content button:nth-of-type(2) {
    background: #6c757d;
  }
  
  .popup-content button:nth-of-type(2):hover {
    background: #5a6268;
  }
  